import React, { useEffect, useMemo, useState, useRef } from "react";
import { Input } from "@/ui/components/Field/Input";
import { Grid } from "@/components/Grid";
import ElementsListItem from "./ElementsListItem";
import Toolbar from "../Toolbar/Toolbar";
import { SelectInput } from "@/ui/components/Field/Select";
import Pagination from "@/ui/components/Pagination/Pagination";
import { useNodes } from "@/api/hooks/useNodes";
import { debounce } from "lodash";
import { useProjectStore } from "@/stores/projectStore";
import { useMetadata } from "@/api/hooks/useMetadata";
import Sidebar from "../layout/Sidebar/Sidebar";
import useResponsive from "@/hooks/useResponsive";
import useSorting from "@/hooks/useSorting";
import { TableCell } from "@/components/Employee/Employees";
import { translate } from "@/i18n";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { objectTypePredicate } from "@/utils/helpers/object-type.helpers";
import { useSettingsSelector } from "@/hooks/use-settings-selector";


const linkProperties = (node, meta) => {
  
  if (!node || !meta) return []
  const keys = Object.keys(node)
  const metaFields = Object.values<any>(meta).find(v => v.fields)?.fields || []

  let propsExtended = {}

  metaFields.forEach(mf => {
    const linkedPropKey = keys.find(k => k.endsWith('_' + mf.id)) || ''
    propsExtended[mf.name] = {
      name: String(mf.name),
      type: String(mf.id),
      value: String(node[linkedPropKey])
    }
  })

  return propsExtended
}

const Elements = () => {
  const nodes = useProjectStore((state) => state.nodes);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [parent, setParent] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [name, setName] = useState<string>("");
  const { sort, direction, handleSort } = useSorting();
  const objectsTypes = useSettingsSelector(({ objectsTypes }) => objectsTypes)
  const objectListCols = useSettingsSelector(({ objectListCols }) => objectListCols, [])

  
  const bodyRef = useRef<HTMLDivElement>(null)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0
    }
  }

  const { data, isLoading } = useNodes({
    page: currentPage - 1,
    name,
    parent,
    type,
    sort,
    direction,
  });
  const { metadata } = useMetadata();
  const intl = useIntl();

  const dataWithProps = useMemo(() => {
    return data?.items.map(d => {
      const seatInfo = d.type_uid || ''
     const meta = metadata?.rawNodes[seatInfo]
  
      const props = linkProperties(d, meta?.plugin_data)
      return {
        ...d,
        ...props
      }
    }) ?? []
  }, [data?.items, metadata])

  const handleNameChange = (e) => setName(e?.target?.value);
  const handleParentChange = (e) => setParent(e?.target?.value);
  const handleTypeChange = (e) => setType(e?.target?.value);
  const { isDesktop } = useResponsive();

  const debouncedNameResponse = useMemo(() => {
    return debounce(handleNameChange, 500);
  }, []);

  useEffect(() => {
    return () => debouncedNameResponse.cancel();
  }, []);

  const extendedRowTemplate = objectListCols.map(v => '1fr').join(' ')
    const rowTemplate = `2fr 1fr 1fr ${extendedRowTemplate} 30px 30px`

  return (
    <Sidebar minWidth={900}>
      <Sidebar.Header title="objects" />

      <Toolbar>
        <Toolbar.Item xs={12} md={4}>
          <Toolbar.Label>{translate("search-by-name")}</Toolbar.Label>
          <Input
            $fullWidth
            placeholder={intl.formatMessage({ id: "search" })}
            onChange={debouncedNameResponse}
          />
        </Toolbar.Item>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.Label>{translate("position")}</Toolbar.Label>
          <SelectInput
            $fullWidth
            placeholder="Выберите расположение"
            value={parent}
            onChange={handleParentChange}
          >
            <option value="">{translate("all-levels")}</option>
            {nodes.map((node) => (
              <option key={node.id} value={node.id}>
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
        <Toolbar.Item xs={6} md={4}>
          <Toolbar.Label>{translate("object-types")}</Toolbar.Label>
          <SelectInput
            $fullWidth
            placeholder="Выберите тип места"
            value={type}
            onChange={handleTypeChange}
          >
            <option value="">{translate("all")}</option>
            {metadata?.nodes?.filter(objectTypePredicate(objectsTypes))?.map((node) => (
              <option key={node.uid} value={node.uid}>
                {node.name}
              </option>
            ))}
          </SelectInput>
        </Toolbar.Item>
      </Toolbar>

      <Table>
        {isDesktop && (
          <Header $cols={rowTemplate}>
            <TableCell
              onClick={handleSort.bind(null, "name")}
              $active={sort === "name"}
              $direction={direction}
            >
              {translate("object-name")}
            </TableCell>
            <TableCell
              onClick={handleSort.bind(null, "parent")}
              $active={sort === "parent"}
              $direction={direction}
            >
              {translate("position")}
            </TableCell>
            <TableCell
              onClick={handleSort.bind(null, "type_name")}
              $active={sort === "type_name"}
              $direction={direction}
            >
              {translate("type")}
            </TableCell>

            {objectListCols.map(col => (
                <TableCell
                 key={col.key}
                >
                {col.name}
              </TableCell>
            ))}
            <Grid.Item />
            <Grid.Item />
          </Header>
        )}

        <Main ref={bodyRef}>
          {dataWithProps.map((spot) => (
            <ElementsListItem key={spot.id} item={spot} />
          ))}
        </Main>

        <Footer>
          <Pagination
            currentPage={currentPage}
            total={data?.total || 0}
            handlePageChange={handlePageChange}
          />
        </Footer>
      </Table>

    </Sidebar>
  );
};

export default Elements;

const Footer = styled.div`
    grid-area: footer;
`

const Table = styled.div`
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-template-areas: 
        'header'
        'main'
        'footer';
`
const Main = styled.div`
  grid-area: main;
  overflow: auto;
  padding: 6px 0;;
`
const Header = styled(Grid.RowHeader)`
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    grid-area: header;
`