import { useBookings } from '@/api/hooks/useBookings'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useNodes } from '@/api/hooks/useNodes'
import { IReport } from '@/api/services/report.service'
import Close from '@/components/Close'
import Modal from '@/components/Modal/Modal'
import SearchIcon from '@/components/icons/SearchIcon'
import FormLoader from '@/components/ui/form/FormLoader'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import { translate } from '@/i18n'
import { useProjectStore } from '@/stores/projectStore'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { printPDF } from '@/utils/func/print'
import { formatToReport } from '@/utils/helpers/dates.helpers'
import { flattenDeep } from 'lodash'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Tooltip } from 'react-tooltip'
import styled from 'styled-components'


type ReportModalProps = {
	item: any
	report: IReport
	onClose: () => void
}

function filterItems(items, filter) {
	return items.filter((item) => {
		return Object.entries(filter).every(([key, value]) => {
			if (value !== '') {
				return String(item[key])
					.toLowerCase()
					.includes(String(value).toLowerCase())
			}
			return true
		})
	})
}

const columns = [
	{ label: '№', key: 'num', withSearch: false }, 
	{ label: 'Корпус, этаж', key: 'location', withSearch: true }, 
	{ label: 'Номер кабинета', key: 'name', withSearch: true }, 
	{ label: 'Тип помещения', key: 'type', withSearch: true }, 
	{ label: 'Подразделение', key: 'department', withSearch: true }, 
	{ label: 'Площадь, кв. м', key: 'area', withSearch: false }, 
	{ label: 'Количество рабочих мест шт.', key: 'seats', withSearch: false }, 
	{ label: 'Количество свободных рабочих мест шт.', key: 'free', withSearch: false }, 
	{ label: 'Количество занятых рабочих мест шт.', key: 'busy', withSearch: false }, 
]
const pdfCols = columns.map(col => ({ header: col.label, dataKey: col.key }))


const toLowerCase = (str: string | undefined | null) => typeof str === 'string' ? str.toLowerCase() : ''

const getLocation = (parentId, nodes = {}) => {
	let currParentId = parentId
	let currLocation: string[] = []

	while(nodes[currParentId] !== undefined && nodes[currParentId].name !== undefined) {
		currLocation.push(nodes[currParentId].name)
		currParentId = nodes[currParentId].parent
	}

	return currLocation.reverse().join('. ').trim()
}

const extractItemFields = (plugins: any) => {
	if (typeof plugins !== 'object') return []
	// @ts-ignore
	const fields = flattenDeep(Object.values(plugins).filter(a => Array.isArray(a)))
	if (!fields || typeof fields !== 'object') return []

	return fields
}

const extractFields = (plugins: any) => {
	if (typeof plugins !== 'object') return []
	// @ts-ignore
	const fields = Object.values(plugins).find(p => p['fields'])?.fields
	if (!fields || typeof fields !== 'object') return []

	return fields
}

const extractFieldValue = (names, fields, typeFields) => {
	const areaField = typeFields.find(v => {
		const areaNames = names.map(toLowerCase)
		return areaNames.includes(toLowerCase(v.name)) || areaNames.includes(toLowerCase(v.extension))
	})

	const area = fields.find(v => v.field_id == areaField.id)
	return area ? area['value'] : ''
}

const unique = (arr: any[]) => {
	if (typeof arr !== 'object' || !Array.isArray(arr)) return []
	return [... new Set(arr)]
}

const extractMetrics = (item, nodes, bookings) => {
	const seatNodes = nodes?.items.filter(n => n.parent == String(item.id)).map(v => String(v.id))

	let seats: string | number = ''
	let freeSeats: string | number = ''
	let busySeats: string | number = ''
	const seatsCount = seatNodes && seatNodes.length > 0 ? seatNodes.length : 0
	
	if (seatsCount > 0 && bookings?.items && seatNodes) {
		const nodeBookings = bookings.items.filter(b => seatNodes.includes(b['bookable_id'])).map(b => b['bookable_id'])
		const bookingsCount = unique(nodeBookings).length
		seats = seatsCount
		freeSeats = seatsCount - bookingsCount
		busySeats = bookingsCount
	}

	return {
		seats,
		free: freeSeats,
		busy: busySeats
	}
}

const useReportData = () => {
	const { data: extra } = useMetadata()
	const { data } = useBookings({
		page: 1,
		perPage: 5000,
		moment: 'current',
		direction: 1
	})

	const { data: nodeData, isLoading } = useNodes({
		page: 1,
		perPage: 10000,
		direction: 1,
	})

	const nodes = useProjectStore((state) => state.nodes)
	const roomsReport = useSettingsSelector(settings => settings.roomsReport, {
		"types": ["Кабинет", "Туадет"],
		"fields": {
		  "area": ["Площадь", "area"],
		  "department": ["Подразделение", "department"]
		}
	})
	const lowerCaseRoomTypes = useMemo(() => roomsReport.types.map(toLowerCase), [roomsReport])

	const nodesObj = useMemo(() => {
		let map = {}
		nodes.forEach(node => {
			map[node.id] = node
		})

		return map
	}, [nodes])

	const mapper = useMemo(() => {
		let map = {}
		const layers = extra && extra.layers ? Object.values(extra.layers) : []

		layers.forEach((layer: any) => {
			if (!layer || typeof layer !== 'object') return
			const fields = extractFields(layer['plugin_data'])

			if (!fields) return

			map[layer.uid] = fields
		})

		return map
	}, [extra])

	return useMemo(() => {
		return nodes.map((item, idx) => {
			const typeName = extra?.layers[item.type_uid]?.name || ''
			const location = getLocation(item.parent, nodesObj)

			if (!typeName || !lowerCaseRoomTypes.includes(toLowerCase(typeName))) return null
			
			const fields = extractItemFields(item.plugin_data)
			const fieldsMeta = mapper[item.type_uid]
			const metrics = extractMetrics(item, nodeData, data)

			return {
				num: idx,
				name: item.name,
				location,
				type: typeName,
				department: extractFieldValue(roomsReport.fields.department, fields, fieldsMeta),
				area: extractFieldValue(roomsReport.fields.area, fields, fieldsMeta),
				seats: metrics.seats,
				free: metrics.free,
				busy: metrics.busy,
			}
		}).filter(v => v)
	}, [extra, nodes, mapper, nodeData, data])
}

const RoomsReport: React.FC<ReportModalProps> = ({
	report,
	item,
	onClose,
}) => {
	const [currentPage, setCurrentPage] = useState<number>(1)
	const [filters, setFilters] = useState<Record<string, string>>({})

	const data = useReportData()

	const reportDataItems = data || []
	const reportItems = filterItems(reportDataItems, filters).map((item, idx) => ({ ...item, num: idx + 1}))

	const handleFilters = (
		column: string,
		event: ChangeEvent<HTMLInputElement>,
	) => {
		setFilters({
			...filters,
			[column]: event.target.value,
		})
	}


	return (
        <Modal maxWidth={1300} isOpen={!!report} onClose={onClose}>
			<Header>
				<Title>База данных</Title>
				<ExportWrapper>
                    <ReportButton
                        onClick={() => printPDF({
                            name: 'База данных' + ` ${formatToReport(new Date())}`,
                            columns: pdfCols,
                            body: reportItems,
							orientation: 'l',
							size: 10
                        })}
                    >
                        PDF
                    </ReportButton>
                    <CSVLink
                        data={reportItems}
                        headers={columns}
                        separator={";"}
                        filename={'База данных' + ` ${formatToReport(new Date())}.csv`}
                    >
                        <ReportButton>CSV</ReportButton>
                    </CSVLink>
                    <Close color="#000" onClick={onClose} />
                </ExportWrapper>
			</Header>

			<div style={{ overflowX: 'auto' }}>
				<Table>
					<thead>
						<tr>
							{columns.map((col) => (
								<th rowSpan={col.withSearch ? 1 : 2} key={col.key}>{col.label}</th>
							))}
						</tr>
						<tr>
							{columns.map((col) => col.withSearch ? (
								<th className="search-row" key={col.key}>
									<div className="search-box">
										<SearchInput
											placeholder="Поиск"
											onChange={(event) => handleFilters(col.key, event)}
										/>
										<SearchIcon />
									</div>
								</th>
							) : null)}
						</tr>
					</thead>
					<tbody>
						{false ? (
							<tr>
								<td colSpan={columns.length + 1}>
									<FormLoader isLoading={true} />
								</td>
							</tr>
						) : reportItems.length ? (
							reportItems
								.slice((currentPage - 1) * 20, currentPage * 20)
								.map((spot) => (
									<ReportRow
										key={spot.id}
										columns={columns}
										item={spot}
										close={close}
									/>
								))
						) : (
							<tr>
								<td colSpan={columns.length + 1}>
									<NotFound>{translate('no-results')}</NotFound>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>

			<Pagination
				inverse
				currentPage={currentPage}
				total={reportItems.length || 0}
				handlePageChange={setCurrentPage}
			/>

			<Tooltip id="create-booking-report-tooltip" />
		</Modal>
	)
}

export default RoomsReport

const SearchInput = styled.input`
	width: 100%;
	border: none;
	padding: 8px 12px;
	outline: none;
	font-size: 12px;
`

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin: 16px 0;

	/* table-layout: fixed; */
	th:first-child,
	td:first-child {
		width: 1%;
		white-space: nowrap;
	}
	th:nth-child(2),
	td:nth-child(2) {
		min-width: 200px;
	}
	th:last-child,
	td:last-child {
		/* width: 90px; */
	}

	th,
	td {
		padding: 8px;
		border: 1px solid #cccccc;
		border-collapse: collapse;
		vertical-align: middle;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.search-row {
		padding: 0;
	}
	.search-box {
		display: flex;
		align-items: center;
		padding-right: 8px;
	}

	th {
		font-weight: 700;
		color: #000;
		font-size: 14px;
		line-height: 16px;
	}

	td {
		font-size: 14px;
		line-height: 16px;

		& > div {
			display: flex;
			align-items: center;
		}
	}
`

const ReportRow = ({ item, columns, close }) => {
	const cols = columns
		.map((column) => {
			let data = item[column.key]

			return {
				key: column.key,
				value: data,
			}
		})
		.filter((v) => v)

	return (
		<tr>
			{cols.map(({ key, value }) => (
				<td key={key}>
					<div style={{ display: 'flex', gap: '8px' }}>
						{value}
					</div>
				</td>
			))}
		</tr>
	)
}


const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
	justify-content: center;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

export const ReportButton = styled.button`
	border: 2px solid #079dac;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 1.6rem;
	line-height: 1.6rem;
	color: #079dac;
	padding: 1.2rem 2rem;
	outline: none;
	background: transparent;
	cursor: pointer;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`

const ExportWrapper = styled.div`
  display: flex;
  align-items: center;

  * {
      &:not(:last-child) {
          margin-right: 0.8rem;
      }
  }

  
  ${media.lg`
      margin-top: 20px;
  `}
`