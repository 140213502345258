import axios from "axios";

export const MailService = {
    async sendEmail(sendEmailDto: any) {
        const data = new FormData()
        data.append('subject', String(sendEmailDto.subject))
        data.append('message', String(sendEmailDto.message))
        data.append('recipient', String(sendEmailDto.recipient))

        return axios.post<any>(
            `/mailto/email.php`, data
        )
    },
    
}