interface IField {
	id: string
	type: string
	value: any
	name: string
}

interface LevelMetadata {
	plugin_data: {
		[key: string]: {
			fields: {
				id: string
				name: string
			}[]
		}
	}
}

interface LevelData {
	plugin_data: {
		[key: string]: {
			field_id: string
			type_id: string
			value: any
		}[]
	}
}

export function processLayerData(
	levelUid: string,
	levelData: LevelData,
	metadata: Record<string, LevelMetadata> = {},
): IField[] | null {
	const levelMetadata = metadata[levelUid]

	if (!levelMetadata) {
		return null // Если метаданные не найдены, возвращаем null
	}

	const pluginData = Object.values(levelMetadata.plugin_data).find(
		(data) => data.fields,
	)?.fields

	const levelFields = Object.values(levelData.plugin_data).reduce(
		(acc, val) => [...acc, ...val],
		[],
	)

	if (!pluginData || !levelFields) {
		return null // Если не найдены соответствующие данные, возвращаем null
	}

	const matchedData: IField[] = []

	levelFields.forEach((field) => {
		const metadataField = pluginData.find((data) => data.id == field.field_id)

		if (metadataField) {
			matchedData.push({
				id: field.field_id,
				type: field.type_id,
				value: field.value,
				name: metadataField.name,
			})
		}
	})

	return matchedData
}
