import { useProjectStore } from '@/stores/projectStore'
import React from 'react'
import DateSelector from './DataSelector'
import { useMetadata } from '@/api/hooks/useMetadata'
import { useLayerView } from '@/api/hooks/useLayerView'
import { useMapStore } from '@/stores/mapStore'

const DateSelectorBar = () => {
	const fullMode = useProjectStore((state) => state.fullMode)
	const noBookFilter = useMapStore((state) => state.noBookFilter)

	if (!fullMode) return null

	if (noBookFilter) return <div />

	return <DateSelector />
}

export default DateSelectorBar
