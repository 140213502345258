import { useMetadata } from '@/api/hooks/useMetadata'
import { useReport } from '@/api/hooks/useReport'
import { IReport } from '@/api/services/report.service'
import Close from '@/components/Close'
import Grid from '@/components/Grid'
import Modal from '@/components/Modal/Modal'
import Toolbar from '@/components/Toolbar/Toolbar'
import FormLoader from '@/components/ui/form/FormLoader'
import { useProjectStore } from '@/stores/projectStore'
import { Input } from '@/ui/components/Field/Input'
import { SelectInput } from '@/ui/components/Field/Select'
import Pagination from '@/ui/components/Pagination/Pagination'
import media from '@/ui/media'
import { formatToAPI, formatToReport } from '@/utils/helpers/dates.helpers'
import { addMinutes, endOfWeek, format, startOfWeek } from 'date-fns'
import { debounce } from 'lodash'
import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { printPDF } from '@/utils/func/print'
import { useIntl } from 'react-intl'
import { translate } from '@/i18n'
import ReportDateSelector from '../Reports/ReportDateSelector'
import { useUsersListerFields } from '@/api/hooks/useUsersListerFields'
import PointIcon from '@/components/icons/PointIcon'
import CalendarIcon from '@/components/icons/CalendarIcon'
import { bookingDialog } from '@/components/shared/booking/form/BookingModal'
import SearchIcon from '@/components/icons/SearchIcon'
import { useMapStore } from '@/stores/mapStore'
import { useGlobalStore } from '@/stores/globalStore'
import { useSettingsSelector } from '@/hooks/use-settings-selector'
import Loader from '@/components/Preloader/Loader'
import Preloader from '@/components/Preloader/Preloader'
import { Tooltip } from 'react-tooltip'
import { RolesEnum, useUserStore } from '@/stores/userStore'

type ReportModalProps = {
	open: boolean
	report: IReport
	close: () => void
}

// function filterItems(items, filter) {
// 	return items.filter((item) => {
// 		return Object.entries(filter).every(([key, value]) => {
// 			if (value !== '') {
// 				return String(item[key])
// 					.toLowerCase()
// 					.includes(String(value).toLowerCase())
// 			}
// 			return true
// 		})
// 	})
// }

function filterItems(items = [], filter) {
	return items.filter((item) => {
		return Object.entries(filter).every(([key, value]) => {
			if (value !== '') {
				const inputValue = (value as string).trim().toLowerCase()
				const itemValue = String(item[key]).toLowerCase()
				return itemValue
					.split(' ')
					.some((w) => inputValue.split(' ').some((i) => w.startsWith(i)))
			}
			return true
		})
	})
}

const CreateBookingReport: React.FC<ReportModalProps> = ({
	report,
	open,
	close,
}) => {
	const [currentPage, setCurrentPage] = useState<number>(1)

	const nodes = useProjectStore((state) => state.nodes)
	const [parent, setParent] = useState<string>('')
	const [filters, setFilters] = useState<Record<string, string>>({})
	const [toggle, setOpen] = useState<boolean>(false)
	const [selection, setSelection] = useState<{
		startDate: any
		endDate: any
		key: string
	}>({
		startDate: startOfWeek(new Date()),
		endDate: endOfWeek(addMinutes(new Date(), -30)),
		key: 'selection',
	})
	const intl = useIntl()
	const { data: extra } = useMetadata()
	const role = useUserStore((state) => state.role)
	const isAdmin = role === RolesEnum.Admin

	
	const handleParentChange = (e) => setParent(e?.target?.value)

	const { data, isLoading } = useReport({
		reportId: report?.report_id,
		params: {
			start: formatToAPI(selection.startDate),
			end: formatToAPI(selection.endDate),
			bookable_only: true,
			type_uid: '',
			root_layer: parent,
			useExtensions: true,
		},
	})

	const fields = useMemo(() => {
		try {
			const nodes = extra?.nodes || []
			const fields = nodes
				.map((node) => {
					const fieldsObj = Object.values(node.plugin_data).find((item) =>
						(item as Record<string, any>).hasOwnProperty('fields'),
					)
					return fieldsObj ? fieldsObj['fields'] : null
				})
				.reduce((acc, val) => (val ? [...acc, ...val] : acc), [])

			return fields
		} catch (e) {
			return []
		}
	}, [extra?.nodes])

	const cols = useSettingsSelector((settings) => settings.createBookingCols, [])
	const baseColumns = report?.columns
		? Object.keys(report.columns).filter((v) =>
				['type_name', 'name', 'parent'].includes(v),
		  )
		: []
	const columns = ['num', ...baseColumns, ...cols.map((col) => col.key)] || []
	const colFields = fields.filter((field) =>
		cols.map((c) => c.name).includes(field.name),
	)
	const colTranslations = cols.reduce(
		(acc, val) => ({
			...acc,
			[val.key]: val.name,
		}),
		{},
	)

	const translations = {
		type_name: intl.formatMessage({ id: 'report-type_name' }),
		name: intl.formatMessage({ id: 'create-booking-report-name' }),
		parent: intl.formatMessage({ id: 'report-parent' }),
		...colTranslations,
	}
	const reportData = data?.report || []
	const reportDataItems =
		reportData.map((item, idx) => {
			let newItem = {
				...item,
				num: idx + 1,
				id: item.id,
				name: item.name,
				parent: item.parent,
				parentId: item.parent,
				type_name: item.type_name,
			}

			const nodeType = nodes.find((node) => node.id == item.parent)
			newItem['parent'] = nodeType?.name || item.parent

			colFields.forEach((field) => {
				const key = cols.find((c) => c.name == field.name)?.key
				if (!key) return

				const props = Object.keys(item)
				const prop = props.find((p) => p.endsWith('_' + field.id))

				if (prop && !newItem[key]) {
					newItem[key] = item[prop]
				}
			})

			return newItem
		}) || []

	const reportItems = filterItems(reportDataItems, filters).map((item, idx) => ({ ...item, num: idx + 1}))

	const handleFilters = (
		column: string,
		event: ChangeEvent<HTMLInputElement>,
	) => {
		setFilters({
			...filters,
			[column]: event.target.value,
		})
	}

	return (
		<ModalWrapper>
			<Header>
				<Title>Поиск свободных мест</Title>
				<Close color="#000" onClick={close} />
			</Header>

			<Toolbar>
				<Toolbar.Item xs={6} md={4}>
					<Toolbar.BlackLabel>Выберите интервал</Toolbar.BlackLabel>
					<Input
						$fullWidth
						value={`${format(
							selection.startDate,
							'dd.MM.yyyy HH:mm',
						)} - ${format(selection.endDate, 'dd.MM.yyyy HH:mm')}`}
						onClick={() => setOpen(true)}
					/>
					<ReportDateSelector
						open={toggle}
						setOpen={setOpen}
						selection={selection}
						setSelection={setSelection}
					/>
				</Toolbar.Item>
				<Toolbar.Item xs={6} md={4}>
					<Toolbar.BlackLabel>Местоположение</Toolbar.BlackLabel>
					<SelectInput $fullWidth value={parent} onChange={handleParentChange}>
						<option value="">{translate('all-levels')}</option>
						{nodes.map((node) => (
							<option key={node.id} value={node.id}>
								{node.name}
							</option>
						))}
					</SelectInput>
				</Toolbar.Item>
			</Toolbar>

			<Table>
				<thead>
					<tr>
						{columns.map((col) => (
							<th key={col}>{translations[col]}</th>
						))}
						{isAdmin && <th />}
					</tr>
					<tr>
						{columns.map((col) => (
							<th className="search-row" key={col}>
								<div className="search-box">
									<SearchInput
										placeholder="Поиск"
										onChange={(event) => handleFilters(col, event)}
									/>
									<SearchIcon />
								</div>
							</th>
						))}
						{isAdmin && <th />}
					</tr>
				</thead>
				<tbody>
					{isLoading ? (
						<tr>
							<td colSpan={columns.length + 1}>
								<FormLoader isLoading={true} />
							</td>
						</tr>
					) : reportItems.length ? (
						reportItems
							.slice((currentPage - 1) * 20, currentPage * 20)
							.map((spot) => (
								<ReportRow
									key={spot.id}
									columns={columns}
									item={spot}
									close={close}
								/>
							))
					) : (
						<tr>
							<td colSpan={columns.length + 1}>
								<NotFound>{translate('no-results')}</NotFound>
							</td>
						</tr>
					)}
				</tbody>
			</Table>

			<Pagination
				inverse
				currentPage={currentPage}
				total={reportItems.length || 0}
				handlePageChange={setCurrentPage}
			/>

			<Tooltip id="create-booking-report-tooltip" />
		</ModalWrapper>
	)
}

export default CreateBookingReport

const SearchInput = styled.input`
	width: 100%;
	border: none;
	padding: 8px 12px;
	outline: none;
	font-size: 14px;
`

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	margin: 16px 0;

	/* table-layout: fixed; */
	th:first-child,
	td:first-child {
		width: 1%;
		white-space: nowrap;
	}
	th:last-child,
	td:last-child {
		width: 90px;
	}

	th,
	td {
		padding: 8px 16px;
		border: 1px solid #cccccc;
		border-collapse: collapse;
		vertical-align: middle;
		white-space: pre-wrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.search-row {
		padding: 0;
	}
	.search-box {
		display: flex;
		align-items: center;
		padding-right: 8px;
	}

	th {
		color: #000;
		font-size: 14px;
		font-weight: 700;
		line-height: 24px;
	}

	td {
		& > div {
			display: flex;
			align-items: center;
		}
	}
`

const ReportRow = ({ item, columns, close }) => {
	const setSeat = useGlobalStore((state) => state.setSeat)
	const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
	const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
	const setZoomSeat = useMapStore((state) => state.setZoomSeat)
	const role = useUserStore((state) => state.role)
	const isAdmin = role === RolesEnum.Admin

	const handleSeatView = (e) => {
		e.preventDefault()
		close()

		setActiveLayer(Number(item.parentId))
		setSeatEmployee(null)
		setSeat(Number(item.id))
		setZoomSeat(Number(item.id))
	}

	const cols = columns
		.map((column) => {
			let data = item[column]

			return {
				key: column,
				value: data,
			}
		})
		.filter((v) => v)

	const handleOpenBooking = () => {
		close()
		bookingDialog({ nodeId: item.id })
	}

	return (
		<tr>
			{cols.map(({ key, value }) => (
				<td key={key}>
					<div style={{ display: 'flex', gap: '8px' }}>
						{key == 'name' && (
							<Grid.Item
								data-tooltip-id="create-booking-report-tooltip"
								data-tooltip-content="Навигация"
							>
								<a href="#" onClick={handleSeatView}>
									<PointIcon color="#2c2c2c" />
								</a>
							</Grid.Item>
						)}
						<div
							style={{ cursor: key === 'name' ? 'pointer' : 'default' }}
							onClick={key === 'name' ? handleOpenBooking : undefined}
						>
							{value}
						</div>
					</div>
				</td>
			))}
			{isAdmin && (
				<td>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<div
							style={{ cursor: 'pointer' }}
							onClick={handleOpenBooking}
							data-tooltip-id="create-booking-report-tooltip"
							data-tooltip-content="Бронирование"
						>
							<CalendarIcon size={24} color="#079DAC" />
						</div>
					</div>
				</td>
			)}
		</tr>
	)
}

const ModalWrapper = styled.div<{ $maxWidth?: number }>`
	background: #ffffff;
	max-width: 1240px;
	width: 100%;
	max-height: 100%;
	max-height: var(--app-height);
	padding: 30px 40px;
	position: relative;
	overflow-y: auto;
	overflow-x: hidden;
	z-index: 20001;
	border-radius: 8px;

	${media.lg`
    padding: 20px;
  `}
	${media.md`
    padding: 10px;
  `}
`

const NotFound = styled.div`
	width: 100%;
	text-align: center;
	padding: 12px 0;
	justify-content: center;
`

const Header = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	${media.lg`
        flex-direction: column;
        align-items: flex-start;
    `}
`

export const ReportButton = styled.button`
	border: 2px solid #079dac;
	box-sizing: border-box;
	border-radius: 4px;
	font-size: 1.6rem;
	line-height: 1.6rem;
	color: #079dac;
	padding: 1.2rem 2rem;
	outline: none;
	background: transparent;
	cursor: pointer;
`

const Title = styled.div`
	font-weight: 500;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #000000;
`
