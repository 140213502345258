import { ProjectService } from '@/api/services/project.service'
import SearchIcon from '@/components/icons/SearchIcon'
import { useToast } from '@/components/shared/toast/useToast'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { useProject } from '@/hooks/useProject'
import { translate } from '@/i18n'
import { useGlobalStore } from '@/stores/globalStore'
import { useProjectStore } from '@/stores/projectStore'
import { lowerCaseString } from '@/utils/helpers/string.helpers'
import debounce from 'lodash/debounce'
import flattenDeep from 'lodash/flattenDeep'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import {
  SearchBookingsIcon,
  SearchLayersIcon,
  SearchPlacesIcon,
  SearchUsersIcon
} from '../../../icons/SearchIcons'
import { filterResults } from './Search'
import SearchItem from './SearchItem'
import SearchItemV2 from './SearchItemV2'
import { filterResultsV2 } from './SearchV2'

const MobileSearch = ({ handleSearchClose }) => {
  const { workspaceId, projectId } = useProject()
  const { enqueueToast } = useToast()
  const searchRef = useRef(null)
  const fullMode = useProjectStore((state) => state.fullMode)

  // local state
  const [bookings, setBookings] = useState(true)
  const [places, setPlaces] = useState(true)
  const [user, setUser] = useState(true)
  const [layers, setLayers] = useState(true)
  const [search, setSearch] = useState('')
  const [focus, setFocus] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState<any[]>([])

  // data selectors
  const setSelector = useGlobalStore((state) => state.setSelector)

  // outside element click listener
  useOnClickOutside(searchRef, () => setFocus(false))

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await ProjectService.search({
        workspaceId,
        projectId,
        term: String(search),
        point: places,
        user: user || bookings,
        layer: layers
      })

      if (response && response.data) {
        const { search_result } = response.data
        const results: any[] = flattenDeep(search_result)

        let result = results
          .map((res) => {
            if (res.type === 'user') {
              res.name = res.display
              const tempRes = res

              const pathBookings =
                Object.keys(res).find((key) => key.includes('locations')) || ''
              const userBookings = JSON.parse(res[pathBookings])

              if (
                bookings &&
                userBookings &&
                userBookings.some((book) =>
                  book?.place_name?.includes(search.trim())
                )
              ) {
                res = null
              }

              if (bookings) {
                const isCorrectSearchBook = lowerCaseString(
                  tempRes.name
                ).includes(lowerCaseString(search))

                if (!isCorrectSearchBook) {
                  return res
                }

                const bookings = userBookings.map((booking) => ({
                  type: 'booking',
                  user: tempRes.display,
                  user_id: tempRes.id,
                  name: tempRes.display,
                  id: booking.booking_id,
                  type_uid: booking.place_type,
                  place: booking.place_name,
                  begin: booking.begin,
                  end: booking.end,
                  layer_id: booking.layer_id,
                  place_id: booking.place_id
                }))

                if (!user) {
                  return bookings
                }

                return [res, ...bookings]
              }

              return res
            }

            return res
          })
          .filter((v) => v)

        if (!user) {
          result = result.filter((item) => item.type != 'user')
        }

        const searchResults = flattenDeep(result)

        setData(filterResults(search, searchResults, bookings))
      }
    } catch (e) {
      enqueueToast(
        { title: 'Ошибка', message: 'Не удалось загрузить данные поиска' },
        { variant: 'error' }
      )
    }
    setLoading(false)
  }

  const fetchDataV2 = async () => {
      setLoading(true)
      try {
        const searchObj = {
          workspaceId,
          projectId,
          // TODO: Fix me plz im broken
          term: search,
          point: places,
          user: fullMode ? user || bookings : false,
          layer: layers
        }
        // const response = await ProjectService.search(searchObj)
        const responseV2 = await ProjectService.searchV2(searchObj)
  
        if (responseV2 && responseV2.data) {
          const { search_result } = responseV2.data
          const results: any[] = flattenDeep(search_result)
  
          const result = results
            .map((res) => {
              if (res['index-type'] === 'user') {
                res.name = res.display
                const tempRes = res
  
                let pathKey = ''
  
                Object.entries(res).forEach(([key, value]) => {
                  const isValidObject = value && typeof value === 'object'
                  if (!isValidObject) return
                  // TODO: make type-guard in newer version
                  const isValidFields = value['locations']
                  if (isValidFields) {
                    pathKey = key
                  }
                  return
                })
  
                const userBookings = res[pathKey]['locations'] || []
  
                if (
                  bookings &&
                  userBookings &&
                  userBookings.some((book) =>
                    book?.place_name?.includes(search.trim())
                  )
                ) {
                  res = null
                }
  
                if (bookings) {
                  const isCorrectSearchBook = lowerCaseString(
                    tempRes.name
                  ).includes(lowerCaseString(search))
                  if (!isCorrectSearchBook && !user) return null
                  if (!isCorrectSearchBook) {
                    return res
                  }
  
                  const bookings = userBookings?.map((booking) => ({
                    'index-type': 'booking',
                    user: tempRes.display,
                    user_id: tempRes.id,
                    name: tempRes.display,
                    id: booking.booking_id,
                    type_uid: booking.place_type,
                    place: booking.place_name,
                    begin: booking.begin,
                    end: booking.end,
                    layer_id: booking.layer_id,
                    place_id: booking.place_id
                  }))
  
                  if (!user) {
                    return bookings
                  }
  
                  // return [res, ...bookings]
                  return res
                }
  
                if (!user) return null
  
                return res
              }
  
              return res
            })
            .filter((v) => v)
  
          const searchResults = flattenDeep(result)
  
          setData(filterResultsV2(search, searchResults, bookings))
        }
      } catch (e) {
        console.error(e)
  
        enqueueToast(
          { title: 'Ошибка', message: 'Не удалось загрузить данные поиска' },
          { variant: 'error' }
        )
      }
      setLoading(false)
  }

  const handleChange = (e) => setSearch(e?.target?.value)
  const handleClose = () => setSelector(null)

  const debouncedResponse = useMemo(() => {
    return debounce(handleChange, 500)
  }, [])

  const fetchSearchFn = process.env.SEARCH_VERSION == 'v2' ? fetchDataV2 : fetchData
  const SearchComponent = process.env.SEARCH_VERSION == 'v2' ? SearchItemV2 : SearchItem

  useEffect(() => {
    if (search) {
      fetchSearchFn()
    }
  }, [search, layers, user, places, bookings])

  useEffect(() => {
    return () => debouncedResponse.cancel()
  }, [])

  return (
    <Wrapper onFocus={() => setFocus(true)}>
      <SearchBox ref={searchRef}>
        <SearchFieldWrapper>
          <SearchWrapper onClick={handleClose}>
            <IconWrapper>
              <SearchIcon />
            </IconWrapper>
            <FormattedMessage id="search">
              {(placeholder) => (
                <SearchInput
                  placeholder={placeholder + '...'}
                  onChange={debouncedResponse}
                />
              )}
            </FormattedMessage>
          </SearchWrapper>

          <FiltersWrapper>
            {fullMode && (
              <FiltersItem>
                <SearchBookingsIcon
                  active={bookings}
                  onClick={() => setBookings(!bookings)}
                />
              </FiltersItem>
            )}
            <FiltersItem>
              <SearchPlacesIcon
                active={places}
                onClick={() => setPlaces(!places)}
              />
            </FiltersItem>
            {fullMode && (
              <FiltersItem>
                <SearchUsersIcon active={user} onClick={() => setUser(!user)} />
              </FiltersItem>
            )}
            <FiltersItem>
              <SearchLayersIcon
                active={layers}
                onClick={() => setLayers(!layers)}
              />
            </FiltersItem>
          </FiltersWrapper>
        </SearchFieldWrapper>

        {search && (
          <SearchResults>
            {data.length ? (
              data.map((item) => (
                <SearchComponent
                  key={item.name + item.id}
                  handleSearchClose={null}
                  data={item}
                />
              ))
            ) : (
              <NotFound>{translate('no-results')}</NotFound>
            )}
            {isLoading && <NotFound>{translate('loading')}....</NotFound>}
          </SearchResults>
        )}
      </SearchBox>
    </Wrapper>
  )
}

export default MobileSearch

const SearchFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
`
const IconWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
`
const FiltersWrapper = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

const FiltersItem = styled.div`
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(:last-child) {
    margin-right: 4px;
  }
`

const NotFound = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`

const SearchResults = styled.div`
  background: #ffffff;
  border-radius: 8px;
  top: 100%;
  margin-top: 6px;
  width: 100%;
  max-height: 500px;
  height: auto;
  box-shadow: 4px 0px 40px rgba(84, 84, 84, 0.25);
  overflow-x: hidden;
  overflow-y: auto;
`

const SearchWrapper = styled.div`
  height: 40px;
  background: #ffffff;
  border-radius: 8px;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  padding: 0px 15px;
  display: flex;
  align-items: center;
  width: 100%;
`

const SearchInput = styled.input`
  border: none;
  outline: none;
  height: 100%;
  width: 100%;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3.2rem;
  color: #000000;
  padding: 1rem;
`

const SearchBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  input {
    margin-right: 0.8rem;
  }
`
