import useWindowSize from '@/hooks/useWindowSize'
import { useMapStore } from '@/stores/mapStore'
import React from 'react'
import styled from 'styled-components'
import MinusIcon from '../icons/MinusIcon'
import PlusIcon from '../icons/PlusIcon'

const Controls: React.FC = () => {
	const { width, height } = useWindowSize()

	// data selectors
	const stage = useMapStore((state) => state.stage)
	const noZoom = useMapStore((state) => state.noZoom)

	const scaleRelativeToPoint = (point, increaseScale) => {
		const scaleBy = 1.25
		const oldScale = stage.scaleX()

		const mousePointTo = {
			x: point.x / oldScale - stage.x() / oldScale,
			y: point.y / oldScale - stage.y() / oldScale,
		}

		const newScale = increaseScale ? oldScale * scaleBy : oldScale / scaleBy
		const x = (point.x / newScale - mousePointTo.x) * newScale
		const y = (point.y / newScale - mousePointTo.y) * newScale

		const newPos = { x, y }

		stage.scale({ x: newScale, y: newScale })
		stage.position(newPos)
		stage.batchDraw()
	}

	if (noZoom) return null

	return (
		<ControlsWrapper>
			<ControlButton
				onClick={scaleRelativeToPoint.bind(
					null,
					{
						x: width / 2,
						y: height / 2,
					},
					true,
				)}
			>
				<PlusIcon />
			</ControlButton>
			<ControlButton
				onClick={scaleRelativeToPoint.bind(
					null,
					{
						x: width / 2,
						y: height / 2,
					},
					false,
				)}
			>
				<MinusIcon />
			</ControlButton>
		</ControlsWrapper>
	)
}

export default Controls

const ControlsWrapper = styled.div`
	position: absolute;
	top: 50%;
	right: 20px;
	transform: translateY(-50%);
`

const ControlButton = styled.div`
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
	transition: all 0.3s;

	background: #ffffff;
	border: 1px solid #fafafa;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

	cursor: pointer;

	&:not(:last-child) {
		margin-bottom: 24px;
	}

	&:hover {
		opacity: 0.7;
	}

	&:active {
		transform: scale(0.8);
	}
`
