import { useToast } from '@/components/shared/toast/useToast'
import { useGlobalStore } from '@/stores/globalStore'
import axios from 'axios'
import { isEqual } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import useSettings from './use-settings'

export function useProjectMulti() {
	const params = useParams<{ workspaceId: string; projectId: string }>()

	const [paramsState, setParamsState] = useState(params)

	useEffect(() => {
		if (!isEqual(paramsState, params)) {
			setParamsState(params)
		}
	}, [params])

	return useMemo(
		() => ({
			workspaceId: Number(paramsState.workspaceId),
			projectId: Number(paramsState.projectId),
		}),
		[paramsState],
	)
}

function useProjectSingle() {
	const project = useGlobalStore((state) => state.project)
	const setProject = useGlobalStore((state) => state.setProject)

	const { data } = useSettings()

	useEffect(() => {
		if (!data) {
			return
		}

		const { workspaceId, projectId } = data

		if (!workspaceId || !projectId) {
			return
		}

		setProject({
			projectId: Number(projectId),
			workspaceId: Number(workspaceId),
		})
	}, [data])

	return project
}

export const useProject = process.env.SINGLE_PROJECT == 'true' ? useProjectSingle : useProjectMulti
