import React from 'react'
import { Grid } from '@/components/Grid'
import { NodeElement } from '@/api/project'
import { useProjectStore } from '@/stores/projectStore'
import { useGlobalStore } from '@/stores/globalStore'
import { useMapStore } from '@/stores/mapStore'
import InfoIcon from '../icons/InfoIcon'
import PointIcon from './../icons/PointIcon'
import MobileElementsItem from './MobileElementsItem'
import useResponsive from '@/hooks/useResponsive'
import styled from 'styled-components'
import { useSettingsSelector } from '@/hooks/use-settings-selector'

type ElementsListItemProps = {
  item: NodeElement
}

const ElementsListItem: React.FC<ElementsListItemProps> = ({ item }) => {
  const setSeat = useGlobalStore((state) => state.setSeat)
  const setSeatEmployee = useGlobalStore((state) => state.setSeatEmployee)
  const setActiveLayer = useGlobalStore((state) => state.setActiveLayer)
  const setZoomSeat = useMapStore((state) => state.setZoomSeat)
  const { isDesktop } = useResponsive()
  const objectListCols = useSettingsSelector(({ objectListCols }) => objectListCols, [])

  const handleSeatView = (e) => {
    e.preventDefault()

    setActiveLayer(Number(item.parent))
    setSeatEmployee(null)
    setSeat(Number(item.id))
    setZoomSeat(Number(item.id))
  }

  const handleSeatChange = (e) => {
    e.preventDefault()
    setSeatEmployee(null)
    setSeat(Number(item.id))
  }

  if (!isDesktop)
    return (
      <MobileElementsItem
        name={item.name}
        item={item}
        handleNavigate={handleSeatView}
        openSeat={handleSeatChange}
      />
    )

    const extendedRowTemplate = objectListCols.map(v => '1fr').join(' ')
    const rowTemplate = `2fr 1fr 1fr ${extendedRowTemplate} 30px 30px`

  return (
    <Row $cols={rowTemplate}>
      <Grid.Item>{item.name}</Grid.Item>
      <Grid.Item>
        <Place item={item} />
      </Grid.Item>
      <Grid.Item>{item.type_name}</Grid.Item>
      {objectListCols.map(col => (<Grid.Item key={item.name}>{getPropValue(item, col)}</Grid.Item>))}
      <Grid.Item>
        <a href="#" onClick={handleSeatView}>
          <PointIcon />
        </a>
      </Grid.Item>
      <Grid.Item>
        <a href="#" onClick={handleSeatChange}>
          <InfoIcon />
        </a>
      </Grid.Item>
    </Row>
  )
}

export const getPropValue = (item, col) => {
  return isNonNullable(item[col.name]?.value) ? item[col.name].value : ''
}

export default ElementsListItem

export const Place = ({ item }) => {
  const nodes = useProjectStore((state) => state.nodes)
  const place = nodes.find((tree) => tree.id == item.parent)

  return <div>{place ? place.name : 'Не указано'}</div>
}

const Row = styled(Grid.Row)`
    &:hover {
        background: rgba(255, 255, 255, 0.15);
    }
`

export function isNonNullable<T>(value: T): value is NonNullable<T> {
  return typeof value !== 'undefined' && value !== null && value !== 'null'
}