import IFrameAuth from '@/components/IFrameAuth'
import NotFound from '@/components/NotFound'
import ProtectedRoutes from '@/components/ProtectedRoutes'
import LogoutPage from '@/pages/logout'
import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes
} from 'react-router-dom'
import LoginContainer from './LoginContainer'
import ProjectContainer from './ProjectContainer'

const AppContainer = () => {
	return (
		<Router>
			<Routes>
				{process.env.SINGLE_PROJECT == 'true' ? (
					<>
						<Route path="*" element={<NotFound />} />
						<Route path="/" element={<IFrameAuth />}>
							<Route path="/login" element={<LoginContainer />} />
							<Route element={<ProtectedRoutes />}>
								<Route index element={<ProjectContainer />} />
								<Route path="/project*" element={<Navigate to="/" />} />
							</Route>
							<Route path="/logout" element={<LogoutPage />} />
						</Route>
					</>
				) : (
					<Route element={<IFrameAuth />}>
						<Route path="/login" element={<LoginContainer />} />
						<Route element={<ProtectedRoutes />}>
							<Route
								path="/project/:workspaceId/:projectId"
								element={<ProjectContainer />}
							/>
						</Route>
						<Route path="/logout" element={<LogoutPage />} />
						<Route index element={<NotFound />} />
					</Route>
				)}
			</Routes>
		</Router>
	)
}

export default AppContainer
