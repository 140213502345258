import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from './components/layout/GlobalStyles'
import { Reset } from './components/layout/Reset'
import AppContainer from './containers/AppContainer'
import blueTheme from './ui/blueTheme'
import whiteTheme from './ui/whiteTheme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-tooltip/dist/react-tooltip.css'
import ReactQueryProvider from '@/lib/react-query'
import ToastProvider from './components/shared/toast/ToastProvider'
import ConfirmDialog from './components/ConfirmDialog'
import { useLayoutEffect } from 'react'
import { LocaleProvider, LOCALES } from './i18n'
import en from 'date-fns/locale/en-GB'
import ru from 'date-fns/locale/ru'
import ModalRoot from '@/components/shared/modal/ModalRoot'
import { Tooltip } from 'react-tooltip'

const selectedLocale = LOCALES.RUSSIAN
export const dateFnsLocale = selectedLocale == LOCALES.ENGLISH ? en : ru

const cancelWheelEvent = (event) => {
	if (event.ctrlKey) {
		event.preventDefault()
	}
}

const App = () => {
	useLayoutEffect(() => {
		const appHeight = () => {
			document.documentElement.style.setProperty(
				'--app-height',
				`${window.innerHeight}px`,
			)
		}
		appHeight()

		window.addEventListener('resize', appHeight)
		document
			.getElementById('root')
			?.addEventListener('wheel', cancelWheelEvent, true)

		return () => {
			window.removeEventListener('resize', appHeight)
			document
				.getElementById('root')
				?.removeEventListener('wheel', cancelWheelEvent, true)
		}
	}, [])

	return (
		<LocaleProvider locale={selectedLocale}>
			<ThemeProvider theme={blueTheme}>
				<ToastProvider>
					<ReactQueryProvider>
						{/* Reset styling */}
						<Reset />
						<GlobalStyles />

						{/* Main Application */}
						<AppContainer />
						<ConfirmDialog />
						<ModalRoot />
					</ReactQueryProvider>
				</ToastProvider>
			</ThemeProvider>
		</LocaleProvider>
	)
}

export default App
